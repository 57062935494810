import { Box, Typography } from "@mui/material";

import { FC } from "react";

const BannerMoonImage = "/images/home/banner-image.svg";
const LogoFacebook = "/images/footer/facebook.svg";
const LogoInstagram = "/images/footer/instagram.svg";
const LogoWhatsapp = "/images/footer/whatsapp.svg";
const SocialMedia: { img: string; link: string }[] = [
  { img: LogoInstagram, link: "www.facebook.com" },
  { img: LogoFacebook, link: "www.facebook.com" },
  { img: LogoWhatsapp, link: "www.facebook.com" },
];

const Footer: FC = () => {
  const openInNewTab = (url: string): void => {
    window.open(url, "_blank");
  };

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        textAlign: "center",
      }}
    >
      <Box sx={{ marginBlock: "70px" }}>
        <img src={BannerMoonImage} width="86px" height="90px" />
        <Typography sx={{ fontSize: "21px", fontWeight: "400" }}>
          عالم القمر الصغير
        </Typography>
        <Typography sx={{ fontSize: "32px", fontWeight: "700" }}>
          تواصلوا معنا
        </Typography>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            mt: "35px",
            "&:hover": {
              cursor: "pointer",
            },
          }}
        >
          {SocialMedia.map(({ img, link }) => (
            <Box
              key={img}
              sx={{ marginInline: "9px" }}
              onClick={() => openInNewTab(link)}
            >
              <img src={img} width="24px" height="24px" />
            </Box>
          ))}
        </Box>
      </Box>
    </Box>
  );
};

export default Footer;
