import { Box, CssBaseline, ThemeProvider, createTheme } from "@mui/material";
import { purple, teal } from "@mui/material/colors";

import Footer from "./components/layout/footer";
import NavBar from "./components/layout/header";
import { RouterProvider } from "react-router";
import { routes } from "./router";

const theme = createTheme({
  palette: {
    primary: teal,
    secondary: purple,
    // text: { primary: "red", secondary: "red", disabled: "red" },
  },
  typography: {
    fontFamily: "almarai",
    allVariants: {
      color: "black",
    },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: `
        @font-face {
          font-family: 'almarai';
          font-style: normal;
          font-display: swap;
          font-weight: 400;
          src: local('almarai');
          unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
        }
      `,
    },
  },
});

function App() {
  return (
    <Box>
      <link rel="preconnect" href="https://fonts.googleapis.com" />
      <link rel="preconnect" href="https://fonts.gstatic.com" />
      <link
        href="https://fonts.googleapis.com/css2?family=Almarai:wght@300;400;700;800&display=swap"
        rel="stylesheet"
      ></link>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Box sx={{ direction: "rtl", height: "100vh" }}>
          {/* <NavBar /> */}
          <RouterProvider router={routes} />
          {/* <Footer /> */}
        </Box>
      </ThemeProvider>
    </Box>
  );
}

export default App;
