import { Stack, Typography } from "@mui/material";

import { FC } from "react";
import { pxToRem } from "../../shared";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import { motion } from "framer-motion";
import StoriesImage from "../../assets/images/home/categories/stories-categories.svg";
import TVImage from "../../assets/images/home/categories/tv.png";
import SingWithMeImage from "../../assets/images/home/categories/sign-with-me.svg";

// const StoriesImage =
//   "../../assets/images/home/categories/stories-categories.svg";
// const TVImage = "../../assets/images/home/categories/tv.png";
// const SingWithMeImage = "../../assets/images/home/categories/sign-with-me.svg";
// const SingWithMeImage = "/images/home/categories/little-moon-singing.png";

interface CardProps {
  bgColor: string;
  img: string;
  text: string;
  height: string;
  width: string;
}
const Card: FC<CardProps> = ({ bgColor, img, text, height, width }) => {
  return (
    <Stack
      direction="column"
      alignItems="center"
      justifyContent="center"
      sx={{
        width: "351px",
        height: "261px",
        borderRadius: "40px",
        backgroundColor: bgColor,
        position: "relative",
        display: "flex",
      }}
    >
      <motion.img
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true }}
        transition={{ duration: 1.3 }}
        // whileHover={{ scale: 1.1 }}
        // whileTap={{ scale: 0.9 }}
        variants={{
          hidden: { y: 40, opacity: 0 },
          visible: {
            y: 0,
            opacity: 1,
          },
        }}
        src={img}
        alt={`categories-${text}`}
        width={width}
        height={height}
        style={{ position: "absolute", top: "-90px" }}
      />
      <Typography
        sx={{
          fontSize: "24px",
          fontWeight: "400",
          width: "200px",
          textAlign: "center",
          // marginTop: "60px",
          mt: "auto",
          mb: 2,
          // backgroundColor: "red",
          // position: "absolute",
        }}
      >
        {text}
      </Typography>
    </Stack>
  );
};

const CategoriesSection: FC = () => {
  const Theme = useTheme();
  const matches = useMediaQuery(Theme.breakpoints.up("md"));
  const isSmall = useMediaQuery(Theme.breakpoints.down("sm"));
  const cardsContent: CardProps[] = [
    {
      img: StoriesImage,
      text: "قصص تربوية تعليمية هادفة",
      bgColor: "#B9E5FB",
      height: "240px",
      width: "240px",
    },
    {
      img: TVImage,
      text: "مسلسلات للأطفال",
      bgColor: "#FFFDDD",
      height: "340px",
      width: "340px",
    },
    {
      img: SingWithMeImage,
      text: "أنشد معي",
      bgColor: "#FCE4EC",
      height: "280px",
      width: "280px",
    },
  ];

  return (
    <Stack direction="column">
      <Typography
        sx={{
          fontSize: {
            xs: pxToRem(50),
            md: pxToRem(70),
          },
          fontWeight: "400",
          textAlign: "center",
          marginBottom: "106px",
          marginTop: {
            xs: "50px",
            md: 0,
          },
        }}
      >
        الكثير من المتعة والفائدة
      </Typography>

      <Stack
        direction={!!matches ? "row" : "column"}
        justifyContent="space-between"
        alignItems="center"
        spacing={!!isSmall ? 15 : 0}
        sx={{
          marginInline: {
            md: "110px",
          },
        }}
      >
        {cardsContent.map(({ bgColor, height, img, text, width }) => (
          <Card
            key={bgColor}
            bgColor={bgColor}
            height={height}
            img={img}
            text={text}
            width={width}
          />
        ))}
      </Stack>
    </Stack>
  );
};

export default CategoriesSection;
