import {
  Box,
  Stack,
  SxProps,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";

import { FC } from "react";
import GifLogo from "../../assets/القمر_الصغيرed2.gif";
import { pxToRem } from "../../shared";

// const BannerMoonImage = "/images/home/banner-image.svg";
const PaperAirPlaneImage = "/images/home/paper-air-plane.svg";
const DoubleQouteImage = "/images/home/banner-double-quote.svg";

const AirPlane: FC = () => {
  return (
    <Box sx={{ position: "absolute", zIndex: "-9999", right: 0, top: "122px" }}>
      <img
        src={PaperAirPlaneImage}
        alt="little-moon-world-banner-image"
        width="331px"
        height="218px"
      />
    </Box>
  );
};

const DoubleQuote: FC<{ scale?: boolean; customSx?: SxProps }> = ({
  scale = false,
  customSx,
}) => {
  return (
    <Box
      sx={{
        position: "absolute",
        zIndex: "-9999",
        right: 0,
        top: "30px",
        ...(!!scale && { transform: "scale(-1, -1)" }),
        ...customSx,
      }}
    >
      <img
        src={DoubleQouteImage}
        alt="little-moon-world-banner-image"
        width="83px"
        height="69px"
      />
    </Box>
  );
};

export const Banner: FC = () => {
  const Theme = useTheme();
  const isSmall = useMediaQuery(Theme.breakpoints.down("sm"));
  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      sx={{
        marginInline: {
          xs: "40px",
          md: "110px",
        },
      }}
    >
      {!!isSmall ? <></> : <AirPlane />}
      <Box>
        <Typography
          className="tracking-in-expand"
          sx={{
            fontSize: {
              xs: pxToRem(50),
              md: pxToRem(70),
            },
            fontWeight: "700",
            mt: {
              xs: "30px",
              md: 0,
            },
          }}
        >
          أنا القمر الصغير
          <br></br>
          قمر ينير عالم الأطفال
        </Typography>
      </Box>

      <Box sx={{ position: "relative" }}>
        {!!isSmall ? <></> : <DoubleQuote />}
        <img
          src={GifLogo}
          alt="little-moon-world-banner-image"
          width={!!isSmall ? "300px" : "800px"}
          height={!!isSmall ? "200px" : "500px"}
        />
        <DoubleQuote scale customSx={{ bottom: "60px", left: 0 }} />
      </Box>
    </Stack>
  );
};

export default Banner;
