import { Box, Typography, useMediaQuery, useTheme } from "@mui/material";
import { FC, useEffect, useRef, useState } from "react";
import BublesImage from "../../assets/images/home/bubles.svg";
import { pxToRem } from "../../shared";

// const BublesImage = "../../assets/images/home/bubles.svg";

export const AboutSection: FC = () => {
  const Theme = useTheme();
  const isSmall = useMediaQuery(Theme.breakpoints.down("sm"));
  const sectionRef = useRef<any>(null);
  const [isVisible, setIsVisible] = useState(false);
  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          setIsVisible(true);
        }
      });
    });

    observer.observe(sectionRef.current);

    // return () => {
    //   observer.unobserve(sectionRef.current);
    // };
  }, []);

  return (
    <Box
      style={{
        position: "relative",
        paddingBlock: "40px",
      }}
    >
      {!!isSmall ? (
        <></>
      ) : (
        <img
          src={BublesImage}
          style={{
            position: "absolute",
            zIndex: "-9999",
            left: 0,
            width: "100%",
          }}
        />
      )}
      <Box
        sx={{
          background: "linear-gradient(125.65deg, #DBFEFD 0.81%, #E4DFFF 100%)",
          borderRadius: !!isSmall ? "26px" : "176.08px 26.41px 26.41px 26.41px",
          paddingTop: "80px",
          paddingBottom: "50px",
          paddingInline: {
            xs: 4,
            md: "95px",
          },
          margin: {
            xs: 1,
            md: "110px",
          },
        }}
      >
        <Typography
          ref={sectionRef}
          className="tracking-in-expand"
          sx={{
            fontWeight: "700",
            fontSize: {
              xs: pxToRem(50),
              md: pxToRem(70),
            },
            marginBottom: "83px",
            textAlign: "center",
          }}
        >
          عالم القمر الصغير
        </Typography>
        <Typography
          className={!!isVisible ? "tracking-in-expand" : ""}
          sx={{
            fontWeight: "400",
            fontSize: {
              xs: pxToRem(26),
              md: pxToRem(40),
            },
          }}
        >
          تأسست القمر الصغير في الشهر الرابع من عام 2000م
          <br></br>  <br></br>  <br></br>  كانت ولادة القمر الصغير بدراً لينير
          دروب الأطفال، وينشر ابتسامته الوادعة على شفاه كل الأطفال... ليس ذلك
          فحسب، بل يقدم أيضاً زهرة حمراء تعبر عن حبه وتواصله مع عقول كل الناشئين
          . <br></br> <br></br>  <br></br> عالم القمر الصغير يتوزع بين إنتاج
          أفلام الرسوم المتحركة، والسلاسل القصصية الموجهة للأطفال واليافعين
          والناشئة، والبرامج التعليمية والثقافية المؤتمتة باستخدام  أجهزة
          الحاسوب وغيرها من المنتجات الموجهة للأطفال واليافعين.
        </Typography>
      </Box>
    </Box>
  );
};

export default AboutSection;
